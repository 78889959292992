/* Import Polaris styles with proper ordering */
@import '@shopify/polaris/build/esm/styles.css';
@import '@shopify/polaris-viz/build/esm/styles.css';
@import '@shopify/polaris-tokens/css/styles.css';
@import 'react-loading-skeleton/dist/skeleton.css';

/* Critical styles first */
:root {
  --p-background: #f1f1f1;
  --font-family-base: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-size-base: 16px;
  --line-height-base: 1.5;
}

/* Base styles */
html {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
}

/* Layout styles */
.Polaris-Frame__TopBar {
  height: 2.5rem;
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: 2.5rem;
}

.Polaris-Frame-ContextualSaveBar {
  height: 3rem;
}

/* Card styles */
.Polaris-LegacyCard + .Polaris-LegacyCard {
  margin-top: var(--p-space-0) !important;
}

/* Links */
a {
  text-decoration: none;
  color: inherit;
}

/* Widget preview */
.widget-preview {
  visibility: hidden;
  transition: visibility 0.2s ease-in-out;
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered
  .widget-preview {
  visibility: visible;
}

/* Performance optimizations */
* {
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

/* Reduce layout shifts */
img,
video {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Improve scrolling performance */
* {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}
